import * as React from "react"
import { graphql } from 'gatsby';
import DataProvider from 'providers/data';
import Link from 'components/Link'
import Layout from 'templates/Layout';
// import Content from 'templates/Content';

import zparse from 'helpers/zparse';

const TeamDetail = (props) => {
	const { edges } = props.data.allContentPage;
	const { edges: peopleEdges } = props.data.allStaffPerson;
	const nodes = edges.map(({ node }) => node);
	let member = peopleEdges.map(({ node }) => node)[0];
	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!member !== false) && props.providerStatusText === 'LOADED') {
		member = props.providerResponse.data[0];
	}

	const Member = ({ firstName, lastName, position, location, bio, photo }) => (
		<section id="member">
			<div className="grid-container">
				<div className="grid-x grid-padding-x">
					<div className="cell small-12 medium-4 no-pad">
						<div className="image-wrapper" style={{ backgroundImage: `url(${photo})` }}></div>
					</div>
					<div className="cell small-12 medium-8 flx">
						<div className="content-wrapper">
							<div className="hide-medium">
								<div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
									<p style={{marginBottom: '0rem', marginTop: '2rem'}}><Link className="button" to={`/about-us/#meet-our-leadership-team`} style={{backgroundColor: '#5fd0df'}}>Back to Leadership</Link></p>
									<h2 style={{paddingTop: '0rem'}}>{firstName} {lastName}</h2>
								</div>
							</div>
							<div className="hide-mobile">
								<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
									<h2 style={{paddingTop: '0rem'}}>{firstName} {lastName}</h2>
									<p style={{marginBottom: '0rem'}}className="back-button"><Link className="button" to={`/about-us/#meet-our-leadership-team`} style={{backgroundColor: '#5fd0df'}}>Back to Leadership</Link></p>
								</div>
							</div>
							<span>{position}  {location}</span>
							<div>{zparse(bio)}</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)

	return (
		<Layout showContactForm={page?.showContactForm} showNewsletterSignUp={page?.showNewsletterSignUp} hoverboards={page?.hoverboards}>
			<Member {...member} />
		</Layout>
	)
}

// export default TeamDetail
const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="StaffPerson" apiParams={{ uri: props.location.pathname }}><TeamDetail {...props} /></DataProvider>
export default getData;

export const query = graphql`
	query ($permalink: String) {
	  allContentPage(
	    filter: {uri: {eq: "/about-us/"}, approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	  ) {
		edges {
			node {
			  pageTitle
			  meta
			  content {
				main
			  }
			  showContactForm
			  showNewsletterSignUp
			  headlineBody
			  headlineTitle
			  headlineImage
			  headlineType
			  hoverboards
			}
		  }
		}
		allStaffPerson(
			filter: {permalink: {eq: $permalink}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
		  ) {
		  edges {
			node {
			  photo
			  firstName
			  lastName
			  position
			  location
			  permalink
			  bio
			}
		  }
		}
	}
`